import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

// Externals
import { Box, IconButton, Typography, TextField, Button } from '@mui/material';
import { Close } from '@mui/icons-material';

import { Formik } from 'formik';
import { t } from '@lingui/macro';

// Components
import Scrollbar from 'components/legacy/components/Scrollbar';

// Steps
import StepSignIn from '../../steps/sign-in';
import ResetPasswordStep from '../../steps/reset-password';

type Steps = 'sign-in' | 'reset-password';

const FormSignIn: FC<any> = () => {
	const [searchParams] = useSearchParams();
	const itemsRef = useRef([]);

	const initialStepSignin = searchParams.get('initialStep_signin');
	const [step, setStep] = useState<Steps>(initialStepSignin === 'reset-password' ? 'reset-password' : 'sign-in');
	console.log('initialStepSignin', initialStepSignin);

	useEffect(() => {
		itemsRef.current = itemsRef.current.slice(0, 6);
	}, []);

	useEffect(() => {
		console.log('step', step);
	}, [step]);

	return (
		<Formik
			enableReinitialize={true}
			initialValues={{
				email: '',
				url: '',
				password: ''
			}}
			onSubmit={() => {}}
			validateOnMount={true}
		>
			{({ handleBlur, handleChange, handleReset, handleSubmit, values, resetForm }) => (
				<form noValidate={true} onReset={handleReset} onSubmit={handleSubmit}>
					<Box
						sx={{
							width: '100%',
							maxWidth: '380px'
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center'
							}}
						>
							<Typography align="center" sx={{ color: '#99A4AE' }} variant="overline">
								{step === 'sign-in' ? t`Connexion` : t`Onboarding`}
							</Typography>
						</Box>

						{/* Formulaire de connexion */}
						{step === 'sign-in' && (
							<StepSignIn handleBlur={handleBlur} handleChange={handleChange} values={values} setStep={setStep} />
						)}

						{/* Formulaire de réinitialisation de mot de passe */}
						{step === 'reset-password' && (
							<Scrollbar options={{ suppressScrollX: true }}>
								<ResetPasswordStep
									initialEmail={values.email}
									onFinished={() => {
										setStep('sign-in');
									}}
									onGoBack={() => {
										setStep('sign-in');
									}}
								/>
							</Scrollbar>
						)}
					</Box>
				</form>
			)}
		</Formik>
	);
};

export default FormSignIn;
